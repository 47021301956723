<template>
  <div v-if="riskType" 
    :class="`inline-flex items-center py-1 px-4 pl-2 rounded-full text-2xl border cursor-pointer ${riskClass}`"
    @click="$emit('set-query-string-to', riskType)"
  >
    <svg class="mr-1 h-5 w-5 fill-current rounded-full border-2 border-white" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="5" />
    </svg>
    <span class="text-gray-800 font-bold">{{ count }}</span>
    <p class="pl-2 pr-4 text-xs font-semibold text-gray-700">{{ title }}</p>
    <button class="flex text-gray-500 hover:text-gray-600 bg-white rounded-full">
      <ChevronRightIcon class="self-center h-4 w-4"/>
    </button>
  </div>
</template>

<script>
import { RiskState } from '@/models/dashboard';
import { ChevronRightIcon } from '@heroicons/vue/solid';

export default {
  name: 'DashboardRollupRiskBadge',
  components: {
    ChevronRightIcon
  },
  props: {
    riskType: {
      type: String,
      validator: (value) => Object.values(RiskState).includes(value)
    },
    count: {
      type: Number
    }
  },
  computed: {
    title() {
      return `${this.riskType} ${this.count === 1 ? 'building' : 'buildings'}`;
    },
    riskClass() {
      switch (this.riskType) {
      case RiskState.Prepared: return 'prepared-text prepared-bg prepared-border';
      case RiskState.Caution: return 'caution-text caution-bg caution-border';
      default: return null;
      }
    }
  }
};
</script>
